/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { featureFlags } from 'client/stores/FeatureFlagsModule';
import loginStore from 'client/stores/loginStore';

export function useFeatureFlags() {
  function hasFeatureFlag(flag: string): boolean {
    return loginStore.getters['FeatureFlagsModule/betaFeatures'][flag];
  }
  return {
    // Example usage:
    // isIdentityFirstFlow: hasFeatureFlag(featureFlags.identityFirst),
    isAdminIdFirst: hasFeatureFlag(featureFlags.adminIdFirst),
    tryAndroid: hasFeatureFlag(featureFlags.tryAndroid),
  };
}
